import Logo from "@common/components/icons/Logo";
import { sessionStore } from "@stores/session";
import { isEmail } from "@common/utils/string";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useCallback, ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "@common/components/ui/form/Form";
import {
  persistUTMQueryParamsToString,
  persistUTMQueryParamsToUrlObject,
} from "@common/utils/router";
import {
  Anchor,
  Box,
  Button,
  Center,
  Flex,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import CenteredLayout from "@common/components/thirdParty/mantine/layout/CenteredLayout";
import { DEFAULT_ERROR_MESSAGE } from "@common/utils/use-query/constants";
import ErrorMessage from "@common/components/text/ErrorMessage";

import {
  MagicLinkConfirmationContent,
  MagicLinkFormControlBox,
} from "@components/layout/MagicLinks";
import { magicLinkStore } from "@stores/magic-link";
import { useConfig } from "@stores/config/hooks";
import { areMagicLinksAllowed } from "@stores/config/utils";

export default function IndexPage(): ReactElement {
  const [isMagicLinkRequested, setIsMagicLinkRequested] = useState(false);
  const [loginFormType, setLoginFormType] = useState<
    "password" | "magic" | null
  >(null);

  const config = useConfig();
  const areMagicLinksEnabled = areMagicLinksAllowed(config);

  useEffect(() => {
    if (config) {
      setLoginFormType(areMagicLinksEnabled ? "magic" : "password");
    }
  }, [areMagicLinksEnabled, config]);

  const isPasswordForm = loginFormType === "password";
  const isMagicForm = loginFormType === "magic";

  const router = useRouter();
  const { query } = router;

  const {
    isLoading: loading,
    error: loginError,
    mutate: login,
    reset: resetLogin,
  } = sessionStore.useLogin();

  const {
    isLoading: isInitiatingMagicLogin,
    error: initiateMagicLoginError,
    mutate: initiateMagicLogin,
    reset: resetInitiateMagicLogin,
  } = magicLinkStore.useInitiateMagicLinkLogin();

  const {
    register,
    watch,
    formState: { isValid },
    handleSubmit,
  } = useForm<{ email: string; password: string }>({
    shouldFocusError: true,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const watchEmail = watch("email");

  const redirect_url =
    typeof query.redirectTo === "string"
      ? decodeURIComponent(query.redirectTo)
      : null;

  const onSubmit = useCallback(
    (details) => {
      if (isMagicForm) {
        initiateMagicLogin(
          {
            email: details.email,
            redirect_url,
          },
          {
            onSuccess: () => setIsMagicLinkRequested(true),
          },
        );
      }

      if (isPasswordForm) {
        login(details);
      }
    },
    [login, isMagicForm, initiateMagicLogin, isPasswordForm, redirect_url],
  );

  const theme = useMantineTheme();

  const isFormLoading = loginFormType === null;
  const shouldRenderForm = !isFormLoading && !isMagicLinkRequested;

  return (
    <CenteredLayout maxWidth="100%" padding={0}>
      <Flex
        align="center"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      >
        {/* Taglines */}
        <Center
          bg="gray.0"
          pl={{ base: theme.spacing.xl * 2, lg: "10%" }}
          pr={theme.spacing.xl * 2}
          py={{ base: theme.spacing.xl * 3, sm: "md" }}
          w={{ base: "100%", sm: "50%" }}
        >
          <Stack w="100%">
            <Flex ml={-15}>
              <Logo width={105} height={105} />
            </Flex>
            <Title color="brand.4" fw={700} m={0} order={1} size="h3">
              Create an account to get started with Metabase
            </Title>
            <Text color="gray.4" fz="sm">
              Get everything you need to start exploring data in five minutes
              with a 14-day trial.
            </Text>
            <div>
              <Button
                data-testid="get-started-button"
                component={Link}
                href={persistUTMQueryParamsToUrlObject(router, "/checkout")}
                mt="xs"
                py="xs"
                w="auto"
              >
                Get started
              </Button>
            </div>
          </Stack>
        </Center>

        {/* Login form */}
        <Center
          bg={theme.fn.lighten(theme.colors.brand[2], 0.75)}
          h={{ base: "auto", sm: "100vh" }}
          px={theme.spacing.xl * 2}
          py={{ base: theme.spacing.xl * 3, sm: "md" }}
          maw={{ base: "none", sm: 420 }}
          w="100%"
        >
          <Stack spacing="sm">
            <Title color="brand.4" fw={700} m={0} order={2} size="h3">
              Log in to manage your Metabase account
            </Title>

            {isFormLoading && <Loader mt="xl" />}

            {shouldRenderForm && (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="sm">
                  <Text color="gray.4" fz="sm">
                    Manage your account, including billing preferences and
                    technical settings.
                  </Text>
                  <TextInput
                    id="email"
                    type="email"
                    label="Email address"
                    placeholder="youlooknicetoday@email.com"
                    onInput={() => {
                      resetInitiateMagicLogin();
                      resetLogin();
                    }}
                    {...register("email", {
                      required: true,
                      validate(value) {
                        return isEmail(value) || "Invalid email";
                      },
                    })}
                  />

                  {isMagicForm && (
                    <>
                      <Button
                        type="submit"
                        disabled={!isValid || loading || isInitiatingMagicLogin}
                        loading={loading}
                      >
                        Continue
                      </Button>
                      {initiateMagicLoginError && (
                        <ErrorMessage>
                          {initiateMagicLoginError.message ||
                            initiateMagicLoginError.status}
                        </ErrorMessage>
                      )}
                    </>
                  )}

                  {isPasswordForm && (
                    <>
                      <TextInput
                        id="password"
                        type="password"
                        label="Password"
                        placeholder="Shh..."
                        onInput={() => {
                          resetInitiateMagicLogin();
                          resetLogin();
                        }}
                        {...register("password", {
                          required: true,
                          shouldUnregister: true,
                        })}
                      />
                      {loginError && loginError.message !== "404" && (
                        <ErrorMessage>
                          {loginError.message ||
                            loginError.status ||
                            DEFAULT_ERROR_MESSAGE}
                          {loginError.status === 401 && (
                            <>
                              &nbsp;You might also be{" "}
                              <Anchor
                                href={persistUTMQueryParamsToString(
                                  router,
                                  "https://www.metabase.com/cloud/login",
                                )}
                                target="_blank"
                                rel="noreferrer"
                                title="Metabase - Your instance"
                              >
                                looking for your instance
                              </Anchor>
                              ?
                            </>
                          )}
                        </ErrorMessage>
                      )}
                      <Flex
                        align="center"
                        direction="row"
                        justify="space-between"
                      >
                        <Text fz="md">
                          <Link
                            href={persistUTMQueryParamsToUrlObject(
                              router,
                              "/forgot-password",
                            )}
                          >
                            Forgot password?
                          </Link>
                        </Text>
                        <Button
                          type="submit"
                          disabled={!isValid || loading}
                          loading={loading}
                        >
                          Login
                        </Button>
                      </Flex>
                    </>
                  )}

                  {/* This box/switch only makes sense if magic links are enabled */}
                  {areMagicLinksEnabled && (
                    <Box mt="xs">
                      <MagicLinkFormControlBox
                        infoText={
                          isPasswordForm
                            ? "Access your account without a password."
                            : "You'll receive a secure login link in your inbox to access your account."
                        }
                        linkText={
                          isPasswordForm
                            ? "Sign in using a secure link."
                            : "Sign in using your password instead."
                        }
                        onClick={() => {
                          // If there was a login error when user tried to log in using password,
                          // we want to clear it when they switch to the magic link login.
                          resetInitiateMagicLogin();
                          resetLogin();

                          setLoginFormType(
                            isPasswordForm ? "magic" : "password",
                          );
                        }}
                      />
                    </Box>
                  )}
                </Stack>
              </Form>
            )}

            {isMagicLinkRequested && (
              <MagicLinkConfirmationContent
                email={watchEmail}
                onClick={() => setIsMagicLinkRequested(false)}
              />
            )}
          </Stack>
        </Center>
      </Flex>
    </CenteredLayout>
  );
}

IndexPage.hideNav = true;
